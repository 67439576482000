
<template>
  <div>
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{$trans('Services')}}
      </span>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
    </h4>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="form-group mr-3">
            <label>{{ $trans('Service Type') }}</label>
            <Select2  class="w-200" v-model="filterQuery.service" :options="seo_services"/>
          </div>
        </div>
        <div class="filter-actions d-flex">
          <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
          <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
            <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Apply') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="services && services.data" class="table-responsive">
          <div class="list-info mb-3">
            <small>{{$trans('Results')}}: <strong>{{services.data.length}}</strong> {{$trans('of')}} <strong>{{services.total}}</strong></small>
          </div>
          <table class="table table-striped mb-3">
            <thead>
            <tr>
              <th>{{$trans('Partner name')}}</th>
              <th>{{$trans('Services')}}</th>
<!--              <th>{{$trans('File Services')}}</th>-->
              <td>{{$trans('Note')}}</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(service,index) in services.data" v-bind:key="index">
              <td>{{service.name}}</td>
              <td>
                <slot v-if="service.services">
                  <span class="badge badge-success badge-pill m-1" v-for="(s,k) in service.services " :key="k">{{$trans(s)}}</span>
                </slot>
              </td>
<!--              <td class="sort-text">-->
<!--                <a target="_blank" :href="service.service_file_url">{{service.service_file_url}}</a>-->
<!--              </td>-->
              <td>{{service.note}}</td>
            </tr>
            </tbody>
          </table>
          <paginate v-if="services && services.data && services.last_page"
                    :page-count="services.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Projects",
  data:function () {
    return {
      process_loading:false,
      formFilter:false,
      filterQuery:{
        page:1,
        keyword: ''
      },
      services:null,
    }
  },
  methods:{
    index:function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/services',params:this.filterQuery, method: 'GET'})
          .then(resp => {
            this.services = resp.data;
            this.process_loading = false
          })
          .catch(err => {
            console.log(err)
          })
    },
    reset_filter:function () {
      this.filterQuery ={
        page:1,
        keyword:'',
      }
      this.index()
    },
    filter:function (){
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
  },
  created() {
    this.index()
  },
  computed:{
    seo_services:function (){
      return this.$root.$data.global_settings.seo_services
    }
  }
}
</script>
<style scoped>

</style>